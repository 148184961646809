<template>
  <div class="p-5 font-size-30">
    <div class="font-weight-bold mb-3">Page introuvable</div>
    <div>Cette page est obsolète, supprimée ou n'existe pas !</div>
    <div class="font-weight-bold font-size-70 mb-1">404 —</div>
    <router-link to="/" class="btn btn-outline-primary width-100"
      >Retourner</router-link
    >
  </div>
</template>
<script>
export default {
  name: "CuiError404",
};
</script>
